import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import {FeesTemplate} from './premiums/premiums.model';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(private http:HttpClient) { }
  baseUrl: string = environment.baseUrl;
  getFeesTemplate():Observable<FeesTemplate[]>{
    return this.http.get(this.baseUrl+'api/Fees/Template').pipe(
      map(
        (res:any)=>{
          return res.data;
        }
      )
    )
  }
  getFeesTemplateById(id):Observable<FeesTemplate>{
    return this.http.get(this.baseUrl+'api/Fees/Template/'+id).pipe(
      map(
        (res:any)=>{
          return res.data[0];
        }
      )
    )
  }

  addFeesTemplate(template):Observable<any>{
    debugger;
    return this.http.post(this.baseUrl+'api/Fees/Template',template).pipe(
      map(
        (res:any)=>{
          return res.result;
        }
      )
    )
  }
  updateFeesTemplate(template,id):Observable<any>{
    return this.http.post(this.baseUrl+'api/Fees/Template/'+id+'/Update',template).pipe(
      map(
        (res:any)=>{
          return res.result;
        }
      )
    )
  }
  deleteFeesTemplate(id):Observable<any>{
    return this.http.delete(this.baseUrl+'api/Fees/Template/'+id).pipe(
      map(
        (res:any)=>{
          return res.result;
        }
      )
    )
  }
}
